<template>
    <FullCalendar defaultView="dayGridMonth" :plugins="plugins" :locale="locale"
                  :events="events" @eventClick="eventClick" :header="header"
                  class="full-calendar" @eventRender="eventRender"/>
</template>

<script>
    import momentPlugin   from '@fullcalendar/moment';
    import dayGridPlugin  from '@fullcalendar/daygrid';
    import timeGridPlugin from '@fullcalendar/timegrid';
    import listPlugin     from '@fullcalendar/list';
    import frLocale       from '@fullcalendar/core/locales/fr';

    import tippy from 'tippy.js';

    const FullCalendar = () => import('@fullcalendar/vue');

    export default {
        name: "ClujiCalendar",
        components: {FullCalendar},
        props: {
            events: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                plugins: [dayGridPlugin, momentPlugin, timeGridPlugin, listPlugin],
                locale: frLocale,
                header: {center: 'dayGridMonth,listWeek,timeGridWeek'},
                eventRender: info => {
                    info.el.setAttribute(
                        'data-tippy-content',
                        info.event.extendedProps.description || info.event.title
                    );
                    info.el.setAttribute('data-tippy-arrow', 'true');

                    tippy(info.el, { allowHTML: true });
                },
                eventClick: info => {
                    if (info.event.extendedProps.route) {
                        info.jsEvent.preventDefault(); // don't let the browser navigate
                        this.$router.push(info.event.extendedProps.route); // Use vue router instead of standard links
                    }
                }
            };
        }
    }
</script>

<style scoped lang="scss">
    @import '~@fullcalendar/core/main.css';
    @import '~@fullcalendar/daygrid/main.css';
    @import '~@fullcalendar/timegrid/main.css';
    @import '~@fullcalendar/list/main.css';
    @import '~tippy.js/dist/tippy.css';

    .full-calendar {
        max-width: 120vh;
        margin: auto;
    }
</style>
